import { render, staticRenderFns } from "./ApprovalForm.vue?vue&type=template&id=339ce861&"
import script from "./ApprovalForm.vue?vue&type=script&lang=js&"
export * from "./ApprovalForm.vue?vue&type=script&lang=js&"
import style0 from "./ApprovalForm.vue?vue&type=style&index=0&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/RevolutionCloud_ra-web-admin_pF1p/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('339ce861')) {
      api.createRecord('339ce861', component.options)
    } else {
      api.reload('339ce861', component.options)
    }
    module.hot.accept("./ApprovalForm.vue?vue&type=template&id=339ce861&", function () {
      api.rerender('339ce861', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/approval/todotask/modules/ApprovalForm.vue"
export default component.exports