var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vxe-modal",
        {
          attrs: {
            width: "60%",
            height: "90%",
            title: _vm.title,
            "show-zoom": true,
            resize: "",
            "lock-view": false,
            mask: true,
            "mask-closable": false,
          },
          on: { close: _vm.onClose },
          model: {
            value: _vm.open,
            callback: function ($$v) {
              _vm.open = $$v
            },
            expression: "open",
          },
        },
        [
          _c("div", { staticClass: "approval" }, [
            _c(
              "div",
              { staticClass: "approval-left" },
              [
                _c(
                  "div",
                  { staticClass: "approval-content" },
                  [
                    _vm.approvalStatusImageUrl
                      ? _c("img", {
                          staticClass: "approval-status-image",
                          attrs: { src: _vm.approvalStatusImageUrl },
                        })
                      : _vm._e(),
                    _c(
                      "a-row",
                      [
                        _c("a-col", { attrs: { span: 6 } }),
                        _c("a-col", { attrs: { span: 6 } }, [
                          _vm._v(" 申请人: "),
                        ]),
                        _c("a-col", { attrs: { span: 6 } }, [
                          _vm._v(" " + _vm._s(_vm.form.applicantName) + " "),
                        ]),
                        _c("a-col", { attrs: { span: 6 } }),
                      ],
                      1
                    ),
                    _c(
                      "a-row",
                      [
                        _c("a-col", { attrs: { span: 6 } }),
                        _c("a-col", { attrs: { span: 6 } }, [
                          _vm._v(" 申请时间: "),
                        ]),
                        _c("a-col", { attrs: { span: 6 } }, [
                          _vm._v(" " + _vm._s(_vm.form.createTime) + " "),
                        ]),
                        _c("a-col", { attrs: { span: 6 } }),
                      ],
                      1
                    ),
                    _c(
                      "a-row",
                      [
                        _c("a-col", { attrs: { span: 6 } }),
                        _c("a-col", { attrs: { span: 6 } }, [
                          _vm._v(" 费用类型: "),
                        ]),
                        _c("a-col", { attrs: { span: 6 } }, [
                          _vm._v(" " + _vm._s(_vm.form.expenseTypeName) + " "),
                        ]),
                        _c("a-col", { attrs: { span: 6 } }),
                      ],
                      1
                    ),
                    _c(
                      "a-row",
                      [
                        _c("a-col", { attrs: { span: 6 } }),
                        _c("a-col", { attrs: { span: 6 } }, [
                          _vm._v(" 申请金额: "),
                        ]),
                        _c("a-col", { attrs: { span: 6 } }, [
                          _vm._v(" " + _vm._s(_vm.form.applyAmount) + " "),
                        ]),
                        _c("a-col", { attrs: { span: 6 } }),
                      ],
                      1
                    ),
                    _c(
                      "a-row",
                      [
                        _c("a-col", { attrs: { span: 6 } }),
                        _c("a-col", { attrs: { span: 6 } }, [
                          _vm._v(" 月费用加总: "),
                        ]),
                        _c("a-col", { attrs: { span: 6 } }, [
                          _vm._v(" " + _vm._s(_vm.form.expenseTotal) + " "),
                        ]),
                        _c("a-col", { attrs: { span: 6 } }),
                      ],
                      1
                    ),
                    _c(
                      "a-row",
                      [
                        _c("a-col", { attrs: { span: 6 } }),
                        _c("a-col", { attrs: { span: 6 } }, [
                          _vm._v(" 所属项目: "),
                        ]),
                        _c("a-col", { attrs: { span: 6 } }, [
                          _vm._v(" " + _vm._s(_vm.form.deptName) + " "),
                        ]),
                        _c("a-col", { attrs: { span: 6 } }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "approval-upload" }, [
                  _vm._v("上传单据"),
                ]),
                _c("a-upload", {
                  attrs: {
                    "list-type": "picture-card",
                    "file-list": _vm.fileList,
                  },
                  on: { preview: _vm.handlePreview },
                }),
                _c(
                  "a-card",
                  {
                    staticClass: "expense-detail",
                    attrs: { title: "采购明细" },
                  },
                  [
                    _c("div", { staticClass: "expense-content-title" }, [
                      _c("span", { staticClass: "left" }),
                      _c("span", { staticClass: "expense-col" }, [
                        _vm._v("采购内容"),
                      ]),
                      _c("span", { staticClass: "expense-col" }, [
                        _vm._v("数量"),
                      ]),
                      _c("span", { staticClass: "expense-col" }, [
                        _vm._v("单位"),
                      ]),
                      _c("span", { staticClass: "expense-col" }, [
                        _vm._v("单价"),
                      ]),
                      _c("span", { staticClass: "expense-col" }, [
                        _vm._v("总价"),
                      ]),
                      _c("span", { staticClass: "right" }),
                    ]),
                    _vm._l(_vm.form.purchaseList, function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "expense-content-item" },
                        [
                          _c("a-form-model-item", { staticClass: "left" }, [
                            _vm._v(" " + _vm._s(index + 1)),
                          ]),
                          _c(
                            "a-form-model-item",
                            { staticClass: "expense-col" },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "first-col",
                                  attrs: { title: item.content },
                                },
                                [_vm._v(" " + _vm._s(item.content))]
                              ),
                            ]
                          ),
                          _c(
                            "a-form-model-item",
                            { staticClass: "expense-col" },
                            [_vm._v(" " + _vm._s(item.quantity))]
                          ),
                          _c(
                            "a-form-model-item",
                            { staticClass: "expense-col" },
                            [_vm._v(" " + _vm._s(item.unit))]
                          ),
                          _c(
                            "a-form-model-item",
                            { staticClass: "expense-col" },
                            [_vm._v(" " + _vm._s(item.price))]
                          ),
                          _c(
                            "a-form-model-item",
                            { staticClass: "expense-col" },
                            [_vm._v(_vm._s(item.amount) + " ")]
                          ),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                ),
                _c("div", [_vm._t("default")], 2),
              ],
              1
            ),
            _vm.approvalRecordVos && _vm.approvalRecordVos.length > 0
              ? _c(
                  "div",
                  { staticClass: "approval-right" },
                  [
                    _c(
                      "a-steps",
                      {
                        staticClass: "approval-step",
                        attrs: {
                          current: _vm.approvalIndex,
                          size: "small",
                          status: _vm.approveStatus,
                          direction: "vertical",
                        },
                      },
                      _vm._l(_vm.approvalRecordVos, function (item, index) {
                        return _c(
                          "a-step",
                          {
                            key: index,
                            attrs: { status: _vm.approveStatusItem(item) },
                          },
                          [
                            _c("template", { slot: "title" }, [
                              _vm._v(
                                " " +
                                  _vm._s(item.approvalBy || item.assignee) +
                                  " "
                              ),
                            ]),
                            _c(
                              "span",
                              {
                                attrs: { slot: "description" },
                                slot: "description",
                              },
                              [_vm._v(_vm._s(_vm.approvalStatusFormat(item)))]
                            ),
                            item.approvalAction == "2" ||
                            item.approvalAction == "4" ||
                            item.approvalAction == "6"
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "approval-time",
                                    attrs: { slot: "description" },
                                    slot: "description",
                                  },
                                  [_vm._v(" " + _vm._s(item.comment) + " ")]
                                )
                              : _vm._e(),
                            item.approvalAction == "3"
                              ? _c("a-icon", {
                                  attrs: { slot: "icon", type: "rollback" },
                                  slot: "icon",
                                })
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticClass: "approval-time",
                                attrs: { slot: "description" },
                                slot: "description",
                              },
                              [_vm._v(_vm._s(item.approvalTime))]
                            ),
                          ],
                          2
                        )
                      }),
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _c(
            "a-modal",
            {
              staticClass: "image-modal",
              attrs: {
                visible: _vm.previewVisible,
                footer: null,
                width: "60%",
              },
              on: { cancel: _vm.handleCancel },
            },
            [
              _c("img", {
                staticStyle: { width: "100%" },
                attrs: { alt: "example", src: _vm.previewImage },
              }),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }