<template>
  <div>
    <vxe-modal
      v-model="open"
      width="60%"
      height="90%"
      :title="title"
      :show-zoom="true"
      resize
      :lock-view="false"
      :mask="true"
      :mask-closable="false"
      @close="onClose"
    >
      <div class="approval">
        <div class="approval-left">
          <div class="approval-content">
            <img class="approval-status-image" :src="approvalStatusImageUrl" v-if="approvalStatusImageUrl" />
            <a-row>
              <a-col :span="6"> </a-col>
              <a-col :span="6">
                申请人:
              </a-col>
              <a-col :span="6">
                {{ form.applicantName }}
              </a-col>
              <a-col :span="6"> </a-col>
            </a-row>
            <a-row>
              <a-col :span="6"> </a-col>
              <a-col :span="6">
                申请时间:
              </a-col>
              <a-col :span="6">
                {{ form.createTime }}
              </a-col>
              <a-col :span="6"> </a-col>
            </a-row>
            <a-row>
              <a-col :span="6"> </a-col>
              <a-col :span="6">
                费用类型:
              </a-col>
              <a-col :span="6">
                {{ form.expenseTypeName }}
              </a-col>
              <a-col :span="6"> </a-col>
            </a-row>
            <a-row>
              <a-col :span="6"> </a-col>
              <a-col :span="6">
                申请金额:
              </a-col>
              <a-col :span="6">
                {{ form.applyAmount }}
              </a-col>
              <a-col :span="6"> </a-col>
            </a-row>
            <a-row>
              <a-col :span="6"> </a-col>
              <a-col :span="6">
                月费用加总:
              </a-col>
              <a-col :span="6">
                {{ form.expenseTotal }}
              </a-col>
              <a-col :span="6"> </a-col>
            </a-row>
            <a-row>
              <a-col :span="6"> </a-col>
              <a-col :span="6">
                所属项目:
              </a-col>
              <a-col :span="6">
                {{ form.deptName }}
              </a-col>
              <a-col :span="6"> </a-col>
            </a-row>
          </div>
          <div class="approval-upload">上传单据</div>
          <a-upload list-type="picture-card" :file-list="fileList" @preview="handlePreview"> </a-upload>
          <!-- <div class="approval-images">
            <ul id="images">
              <li><img :src="item.url" v-for="(item, index) in fileList" :key="index" @click="handleShow" /></li>
            </ul>
          </div> -->
          <a-card title="采购明细" class="expense-detail">
            <div class="expense-content-title">
              <span class="left"></span>
              <span class="expense-col">采购内容</span>
              <span class="expense-col">数量</span>
              <span class="expense-col">单位</span>
              <span class="expense-col">单价</span>
              <span class="expense-col">总价</span>
              <span class="right"></span>
            </div>
            <div class="expense-content-item" v-for="(item, index) in form.purchaseList" :key="index">
              <a-form-model-item class="left"> {{ index + 1 }}</a-form-model-item>
              <a-form-model-item class="expense-col"><span class="first-col" :title="item.content"> {{ item.content }}</span></a-form-model-item>
              <a-form-model-item class="expense-col"> {{ item.quantity }}</a-form-model-item>
              <a-form-model-item class="expense-col"> {{ item.unit }}</a-form-model-item>
              <a-form-model-item class="expense-col"> {{ item.price }}</a-form-model-item>
              <a-form-model-item class="expense-col">{{ item.amount }} </a-form-model-item>
            </div>
          </a-card>

          <div>
            <slot></slot>
          </div>
        </div>
        <div class="approval-right" v-if="approvalRecordVos && approvalRecordVos.length > 0">
          <a-steps
            :current="approvalIndex"
            size="small"
            class="approval-step"
            :status="approveStatus"
            direction="vertical"
          >
            <a-step v-for="(item, index) in approvalRecordVos" :status="approveStatusItem(item)" :key="index">
              <template slot="title">
                {{ item.approvalBy || item.assignee }}
              </template>
              <span slot="description">{{ approvalStatusFormat(item) }}</span>
              <!-- 显示拒绝与暂停原因 -->
              <div
                slot="description"
                class="approval-time"
                v-if="item.approvalAction == '2' || item.approvalAction == '4' || item.approvalAction == '6'"
              >
                {{ item.comment }}
              </div>
              <a-icon v-if="item.approvalAction == '3'" slot="icon" type="rollback" />
              <div slot="description" class="approval-time">{{ item.approvalTime }}</div>
            </a-step>
          </a-steps>
        </div>
      </div>
      <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel" class="image-modal" width="60%">
        <img alt="example" style="width: 100%" :src="previewImage" />
      </a-modal>
    </vxe-modal>
  </div>
</template>

<script>
import { Steps } from 'ant-design-vue'
// npm install viewerjs
// import Viewer from 'viewerjs'
// import 'viewerjs/dist/viewer.css'
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
export default {
  components: {
    ASteps: Steps,
    AStep: Steps.Step
  },
  props: {
    title: {
      type: String,
      required: true
    },
    form: {
      type: Object,
      required: true
    },
    approvalRecordVos: {
      type: Array,
      required: true
    },
    open: {
      type: Boolean,
      required: true
    },
    expenseTypeOptions: {
      type: Array,
      required: true
    },
    approvalIndex: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      fileList: [],
      previewVisible: false,
      gallery: null
    }
  },
  mounted() {
    console.log('open', this.open)
  },
  computed: {
    approveStatus() {
      var approvalResult = null
      if (this.approvalRecordVos && this.approvalRecordVos.length > 0) {
        approvalResult = this.approvalRecordVos[this.approvalIndex - 1].approvalAction
      }
      switch (approvalResult) {
        case '2':
          return 'error'
        case '4':
          return 'finish'
        default:
          return 'process'
      }
    },
    approvalStatusImageUrl() {
      if (this.form.approvalStatus === '2') {
        return require('@/assets/images/refuse.png')
      } else if (this.form.approvalStatus === '3') {
        return require('@/assets/images/reject.png')
      } else if (this.form.approvalStatus === '4') {
        return require('@/assets/images/pass.png')
      } else if (this.form.approvalStatus === '5') {
        return require('@/assets/images/pay.png')
      } else if (this.form.approvalStatus === '6') {
        return require('@/assets/images/stop.png')
      } else {
        return ''
      }
    }
  },
  watch: {
    form(newVal, oldVal) {
      this.form.expenseTypeName = this.selectDictLabel(this.expenseTypeOptions, this.form.expenseType)
      this.setFileList(this.form.billImageUrl)
    },
    open(newVal, oldVal) {
      // setTimeout(() => {
      //   const imageNode = document.getElementById('images')
      //   if (imageNode) {
      //     this.gallery = new Viewer(imageNode)
      //   }
      // }, 1000)
    }
  },
  methods: {
    handleShow() {
      this.gallery.show()
    },
    handleCancel() {
      this.previewVisible = false
    },
    async handlePreview(file) {
      if (file.url.indexOf('.pdf')) {
        window.open(file.url)
      } else {
        if (!file.url && !file.preview) {
          file.preview = await getBase64(file.originFileObj)
        }
        this.previewImage = file.url || file.preview
        this.previewVisible = true
      }
    },
    setFileList(d) {
      this.fileList = []
      if (!d) {
        return
      }
      if (Array.isArray(d)) {
        d.forEach(p => {
          this.fileList.push({
            uid: '-1',
            url: p
          })
        })
      }
    },
    onClose() {
      this.$emit('onClose')
    },
    approvalStatusFormat(item) {
      if (item.approvalAction) {
        switch (item.approvalAction) {
          case '1':
            return '待审批'
          case '2':
            return '拒绝'
          case '3':
            return '已撤销'
          case '4':
            return '同意'
          case '6':
            return '暂停'
          default:
            return '流程中'
        }
      } else {
        switch (item.approvalState) {
          case 1:
            return '待审批'
          case 2:
            return '拒绝'
          case 3:
            return '已撤销'
          case 4:
            return '同意'
          case 6:
            return '暂停'
          default:
            return '流程中'
        }
      }
    },
    approveStatusItem(item) {
      const approvalResult = item.approvalAction || item.approvalState + ''
      switch (approvalResult) {
        case '2':
          return 'error'
        case '4':
          return 'finish'
        default:
          return 'wait'
      }
    }
  }
}
</script>
<style lang="less">
.approval-step {
  .ant-steps-item {
    min-width: 160px;
    margin-bottom: 20px;
  }
}
.approval-content {
  position: relative;
  border: 1px solid #e8e8e8;
  margin-bottom: 10px;
  .ant-row {
    padding-top: 10px;
  }
  .ant-row:first-child {
    padding-top: 10px;
  }
  .ant-row:last-child {
    padding-bottom: 10px;
  }
}
.approval-select {
  margin: 20px 0;
}
.approval-reason {
  margin-top: 10px;
  margin-bottom: 20px;
  .ant-form-item-label {
    margin-right: 10px;
  }
  .ant-form-item {
    display: flex;
    width: 100%;
  }
  .ant-form-item-control-wrapper {
    width: 100% !important;
  }
  .expense-content-item {
    > .left {
      height: 40px;
      line-height: 40px;
    }
  }
}
.expense-content-title,
.expense-content-item {
  display: flex;
  height: 40px;
  .expense-col {
    width: 25% !important;
    margin: 0 3px;
    text-align: center;
  }
  .first-col {
    width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
  }
  > .left {
    flex: 0 0 35px;
    min-width: 35px;
    text-align: right;
    padding-right: 3px;
  }
}

.expense-content-title {
  .expense-col {
    font-weight: bold;
    text-align: center;
  }
}

.approval {
  display: flex;
  height: 100%;
  .approval-left {
    flex: 1;
    margin-top: 10px;
    padding-right: 10px;
  }
  .approval-right {
    flex: 0 0 300px;
    padding-left: 10px;
    padding-top: 20px;
    border-left: 1px solid #e8e8e8;
    // height: 99%;
    // overflow: auto;
  }
}
.aprroval-btns {
  .bottom-control {
    margin-top: 20px;
    text-align: center;
    border-top: 0 !important;
  }
}
.ant-drawer-approval {
  .ant-drawer-body {
    margin-top: 30px !important;
  }
}
.approval-status-image {
  position: absolute;
  width: 100px;
  height: 100px;
  top: 32px;
  right: 32px;
  z-index: 9999;
}

.approval-left .anticon-delete {
  display: none !important;
}
.approval-upload {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 16px;
  font-weight: 550;
}
.image-modal {
  z-index: 99999;
}
// .approval-images {
//   ul {
//     margin: 0;
//     padding: 0;
//   }
//   img {
//     width: 100px;
//     height: 100px;
//     margin-right: 10px;
//     margin-bottom: 10px;
//     border: 1px solid #d9d9d9;
//     border-radius: 10px;
//     padding: 8px;
//   }
// }
</style>
